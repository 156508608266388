@import 'fonts/fonts';

/* // Tailwindcss */
@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
/* Custom Css */

@import 'plugins/gridjs';
@import 'plugins/select';
@import 'plugins/slider';
@import 'plugins/dropzon';
@import 'plugins/others';

.group\/menu-link:hover,
.animate {
  animation-iteration-count: 2;
  stroke-dasharray: 10;
}
