.slick-dotted.slick-slider {
    @apply mb-0;
 }
 
 .slick-slider {
    .slick-dots {
       @apply mb-10;
 
       li button {
          @apply bg-custom-500 h-4 w-4 border-4 border-white border-solid dark:border-zinc-900 outline outline-1 outline-custom-500 p-0 before:hidden opacity-30 rounded-full;
       }
 
       li.slick-active {
          button {
             @apply opacity-100;
          }
       }
    }
 
    .slick-next {
       right: 20px;
    }
 
    .slick-prev {
       left: 20px;
       z-index: 1;
    }
 }