/* Importing Select SCSS file. */

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'ngx-lightbox/lightbox.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

.clientTable .datatable-row-center :nth-child(2) > .datatable-body-cell-label,
.clientTable .datatable-row-center :nth-child(3) > .datatable-body-cell-label,
.clientTable .datatable-row-center :nth-child(5) > .datatable-body-cell-label,
.clientTable .datatable-row-center :nth-child(6) > .datatable-body-cell-label,
.clientTable .datatable-row-center :nth-child(7) > .datatable-body-cell-label,
.clientTable .datatable-row-center :nth-child(8) > .datatable-body-cell-label {
  text-align: center;
}

.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(2),
.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(3),
.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(5),
.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(6),
.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(7),
.clientTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(8) {
  text-align: center;
}

.howToVideoTable
  .datatable-row-center
  :nth-child(3)
  > .datatable-body-cell-label,
.howToVideoTable
  .datatable-row-center
  :nth-child(4)
  > .datatable-body-cell-label,
.howToVideoTable
  .datatable-row-center
  :nth-child(5)
  > .datatable-body-cell-label {
  text-align: center !important;
}

.howToVideoTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(3),
.howToVideoTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(4),
.howToVideoTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(5) {
  text-align: center !important;
}

.smsSettingTable
  .datatable-row-center
  :nth-child(1)
  > .datatable-body-cell-label,
.smsSettingTable
  .datatable-row-center
  :nth-child(2)
  > .datatable-body-cell-label,
.smsSettingTable
  .datatable-row-center
  :nth-child(3)
  > .datatable-body-cell-label
  .smsSettingTable
  .datatable-row-center
  :nth-child(4)
  > .datatable-body-cell-label {
  text-align: center !important;
}

.smsSettingTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center {
  text-align: left !important;
}

.childClientTable .datatable-body-cell {
  display: flex !important;
  justify-content: center !important;
}

.childClientTable .datatable-header-cell{
  display: flex !important;
  justify-content: center !important;
}

// .smsSettingTable
//   .datatable-header
//   > .datatable-header-inner
//   > .datatable-row-center
//   > *:nth-child(1),
// .smsSettingTable
//   .datatable-header
//   > .datatable-header-inner
//   > .datatable-row-center
//   > *:nth-child(2),
// .smsSettingTable
//   .datatable-header
//   > .datatable-header-inner
//   > .datatable-row-center
//   > *:nth-child(3) {
//   text-align: center !important;
// }

.moderationTable
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(4) {
  text-align: center !important;
}

.moderationTable
  .datatable-row-center
  :nth-child(4)
  > .datatable-body-cell-label
  > div {
  justify-content: center !important;
}

.moderationTable
  .datatable-row-center
  :nth-child(3)
  > .datatable-body-cell-label
  > div
  span {
  word-break: break-word !important;
}

.actionbtn > div {
  width: auto !important;
}

.step {
  pointer-events: none !important;
}

.transform-none {
  transform: none !important;
}

.ck-content {
  min-height: 200px !important;
}

.swal2-popup.swal2-modal.custom-popup-class {
  width: 22em;
  padding-bottom: 80px;
  background-color: #ffffff;
  display: grid;
}
.swal2-popup.swal2-modal.custom-popup-class-conformation {
  width: 22em;
  background-color: #ffffff;
  // min-width: 22em;
  // background-color: #ffffff;
  // max-width: 26rem;
  // width: 100%;
}

/* Css Added By Nilesh
=================================*/
div.card {
  box-shadow: 0px 0px 6px #00000029;
}
div.card.shadow-none {
  box-shadow: none;
}
.app-menu {
  box-shadow: 1px 0px 5px #00000029;
}
// ul#navbar-nav li a span {
//   color: #94a3b8;
// }

// ul#navbar-nav li a.group-data-\[sidebar\=light\]\:bg-black :hover i {
//   color: black;
// }

.datatable-header-inner .datatable-header-cell {
  font-weight: 500;
  letter-spacing: 0.04rem;
}
.ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  border-bottom: 0;
}
pagination-controls li a.active {
  background: #c7d9fb !important;
  color: #3b82f6 !important;
  border-color: #c7d9fb !important;
}
.userDropdownMenu.dropdown-menu {
  transform: translate(17px, 55px) !important;
  border-radius: 0;
  box-shadow: 0px 0px 6px #00000029;
  min-width: 260px;
}
body {
  font-weight: 300;
  letter-spacing: 0.02rem;
}

// .simplebar-content ul li a.group\/menu-link:hover {
//   animation: none;
//   stroke-dasharray: none;
// }

#otpInput div input {
  width: 42px !important;
  height: 56px !important;
  border-radius: 5px;
  color: #000000;
  background: transparent;
}

#ngOtpInputJoinCommunity div input {
  width: 50px !important;
  height: 80px !important;
  border-radius: 5px;
  color: #000000;
  border: 1px solid #e0e3e8 !important;
  background: transparent;
  margin-right: 10px;
  border: none;
}
.custom-ng-select .ng-select-container .ng-arrow-wrapper {
  display: none;
}
.custom-ng-select .ng-select-container {
  min-height: 45px;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 50%;
  transform: translateY(-50%);
}
.custom-ng-select .ng-value-container {
  margin-left: 24px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 0;
}
.ng-select-container.ng-clear {
  display: none !important;
}
.material.noData .empty-row {
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
  // background-color: #000000;
}
.btn {
  font-weight: 500;
  letter-spacing: 0.02rem;
}
.ng-select.ng-select-single.custom-ng-select .ng-select-container {
  height: 45px;
  position: relative;
  border-radius: 0.375rem;
}
.ng-select.ng-select-single.custom-ng-select .ng-select-container:after {
  content: '\ea4a';
  font-family: 'remixicon';
  font-size: 1.1rem;
  position: absolute;
  right: 0.6rem;
  color: #bcc9dd;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.custom-ng-select .ng-value-container {
  margin-left: 0;
}
.ng-select .ng-select-container .ng-value-container {
  padding: 0 1rem;
}
.ng-select.custom-ng-select .ng-value {
  font-size: 12px;
  width: 100%;
  position: relative;
}
// .ng-select .ng-select-container .ng-clear-wrapper {
//   display: none;
// }
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 0.6rem 0.3rem !important;
}
.ng-select.ng-select-multiple.select-no-arrow
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  padding-top: 6px;
  padding-left: 0.5rem;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  width: auto;
}
.ng-select.ng-select-single.custom-ng-select.select-no-arrow
  .ng-select-container:after {
  display: none;
}
.ng-select .ng-clear-wrapper {
  margin-right: 26px;
}
.ng-select .ng-clear-wrapper {
  margin-right: 26px;
  margin-top: 4px;
}

.clientTable
  .datatable-body-row
  .datatable-body-cell:nth-child(2)
  .datatable-body-cell-label
  div/*,
.clientTable
  .datatable-body-row
  .datatable-body-cell:nth-child(3)
  .datatable-body-cell-label
  div*/ {
  justify-content: center;
}
.ng-select.filter-select .ng-select-container .ng-value-container {
  padding-left: 2rem;
}
.ng-select.filter-select.ng-select-searchable
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding-left: 1.7rem;
  padding: 0;
}
.ngx-datatable .font-medium {
  font-weight: 400;
}
.text-18 {
  font-size: 1.125rem;
}
.ng-select.sportsList-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding: 0.4rem !important;
}
.form-input {
  font-weight: 400;
  color: #68778e;
}
[data-mode='light'] .slick-next:before {
  color: #000000;
}
[data-mode='light'] .slick-prev:before {
  color: #000000;
}

.form-input,
.form-file {
  height: 45px;
  font-size: 0.86rem !important;
  font-weight: 400;
}
.ng-select .ng-select-container .ng-placeholder {
  font-size: 0.86rem !important;
  font-weight: 400;
  opacity: 0.78;
  margin-top: 0.2rem;
}
.ng-select .ng-select-container .ng-value-label {
  font-size: 0.86rem !important;
  font-weight: 400;
  margin-top: 0.2rem;
  display: block;
}
.form-file {
  line-height: 39px;
}
textarea.form-input {
  height: 170px;
  padding-top: 1rem;
}

.card .top-card-user-img {
  width: 74px;
  height: 74px;
  min-width: 74px;
}
.card .card-user-img.size-24 { width: 6rem;
  height: 6rem;
  min-width: 6rem; }

.card .card-user-img {
  // min-width: 60px;
  // width: 60px;
  // height: 60px;
  width: 74px;
  height: 74px;
  min-width: 74px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 500;
}
@media only screen and (max-width: 1700px) {
  // .card .card-user-img {
  //   min-width: 55px;
  //   width: 55px;
  //   height: 55px;
  //   font-size: 25px;
  // }
}
@media only screen and (max-width: 1600px) {
  // .card .card-user-img {
  //   font-size: 18px;
  // }
}
@media only screen and (max-width: 1535px) {
  // .card .card-user-img {
  //   min-width: 70px;
  //   width: 70px;
  //   height: 70px;
  //   font-size: 32px;
  // }
}

.apexCircleChart .apexcharts-svg .apexcharts-legend {
  left: 0 !important;
  top: 0 !important;
}
.apexCircleChart .apexcharts-svg .apexcharts-legend-text {
  font-size: 12px !important;
}
.apexCircleChart .apexcharts-svg .apexcharts-legend-marker {
  margin-right: 6px !important;
  height: 12px !important;
  width: 12px !important;
  top: 1px !important;
}
.apexCircleChart .apexcharts-svg .apexcharts-legend-series {
  line-height: 1;
  margin: 0.4rem 0 !important;
}
.apexTalabelChart .apexcharts-data-labels text {
  fill: #fff !important;
  position: relative;
  transform: translateY(20px);
}
.apexTalabelChart .apexcharts-toolbar {
  display: none !important;
}
.post-user-img {
  min-width: 2.25rem;
  min-height: 2.25rem;
}
.ngx-datatable .datatable-footer {
  border-top: 1px solid #e5e7eb;
}
:is([data-mode='dark'] .ngx-datatable .datatable-footer) { --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); }
:is([data-mode='dark'] div .ng-select.ng-select-single.custom-ng-select .ng-select-container), :is([data-mode=dark] div .ng-select .ng-select-container) { --tw-text-opacity: 1; color: rgb(188 201 221 / var(--tw-text-opacity)); --tw-bg-opacity: 1; background-color: rgb(28 47 69 / var(--tw-bg-opacity)); --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); }
:is([data-mode='dark'] div .ng-select .ng-select-container .ng-value-label) { --tw-text-opacity: 1 !important; color: rgb(188 201 221 / var(--tw-text-opacity)) !important; }
:is([data-mode='dark'] div .ng-select.custom-ng-select .ng-select-container) { --tw-text-opacity: 1; color: rgb(188 201 221 / var(--tw-text-opacity)); --tw-bg-opacity: 1; background-color: rgb(28 47 69 / var(--tw-bg-opacity)); --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); }
.ng-select.custom-ng-select.footer-select .ng-value { font-size: 0.86rem !important; font-weight: 400; color: #68778e; }
:is([data-mode='dark'] div .ng-select.custom-ng-select.footer-select .ng-value) { --tw-text-opacity: 1; color: rgb(188 201 221 / var(--tw-text-opacity)); }
:is([data-mode='dark'] .ck.ck-editor__main > .ck-editor__editable) { --tw-text-opacity: 1; color: rgb(188 201 221 / var(--tw-text-opacity)); --tw-bg-opacity: 1; background-color: rgb(19 35 55 / var(--tw-bg-opacity)); --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); }
:is( [data-mode='dark'] .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused)) { --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); }
:is([data-mode='dark'] .ck-reset_all :not(.ck-reset_all-excluded *)), :is([data-mode='dark'] .ck.ck-reset_all) { color: rgb(188 201 221 / var(--tw-text-opacity)); --tw-bg-opacity: 1; }
:is([data-mode='dark'] .ck.ck-toolbar) { --tw-border-opacity: 1; border-color: rgb(35 58 87 / var(--tw-border-opacity)); --tw-bg-opacity: 1; background-color: rgb(26 47 72 / var(--tw-bg-opacity)); }

.tooltip { position: relative; display: inline-block; }
.tooltip-text { visibility: hidden; font-size: 13px; font-weight: 500; font-family: "Poppins", sans-serif; background-color: #e2e8f0; color: #64748b; text-align: center; border-radius: 6px; padding: 5px 12px; position: absolute; z-index: 1; bottom: 100%; left: 50%; transform: translateX(-50%); margin-bottom: 0; opacity: 0; visibility: hidden; transition: 0.35s all; }
.copy { @extend .tooltip-text; }
.copied { @extend .tooltip-text; background-color: #249782; color: #fff; }
.tooltip-text:after, .copied:after, .copy:after { content: ""; position: absolute; left: 0; right: 0; top: 100%; height: 0; width: 0; border-top: 5px solid #e2e8f0; border-left: 5px solid transparent; border-right: 5px solid transparent; margin: auto; transition: 0.35s all; }
.tooltiptext.copied:after { border-top-color: #249782; }
.tooltip:hover .tooltiptext { visibility: visible; opacity: 1; margin-bottom: 10px; }
.datatable-body-cell-label .tooltip-text {  right: 100%; left: auto; bottom: auto; top: 50%; transform: translateY(-50%); margin: 0; }
.datatable-body-cell-label .tooltip-text::after { left: 100%; right: auto; top: 0; bottom: 0; border-left: 5px solid #e2e8f0; border-top: 5px solid transparent; border-bottom: 5px solid transparent; }
.datatable-body-cell-label .tooltip:hover .tooltip-text { opacity: 1; visibility: visible; margin-right: 10px; }

.organizationSelect .ng-select .ng-dropdown-panel .ng-dropdown-panel-items{ max-height: 190px !important; }
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell, .datatable-row-wrapper .datatable-row-group .datatable-body-cell { min-width: 160px; }
.datatable-body-cell-label { white-space: nowrap; }
:is([data-mode='dark'] .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month) { background-color: #132337 !important; }
:is([data-mode='dark'] .ng-select .ng-select-container .ng-value-container .ng-placeholder) { --tw-text-opacity: 1 !important; color: rgb(188 201 221 / var(--tw-text-opacity)) !important; opacity: 1; }
:is([data-mode='dark'] input::placeholder), :is([data-mode='dark'] textarea::placeholder) { color:rgb(188 201 221); opacity: 1; }

.create-client .imageCropper-custom{ height: 35rem; overflow-x: scroll; }
// .ng-select.filter-select.ng-select-multiple.select-no-arrow .ng-select-container .ng-value-container .ng-placeholder { padding-top: 0; }
.ng-select.filter-select.ng-select-searchable .ng-select-container .ng-value-container .ng-input { position: static; }
.ng-select.filter-select .ng-select-container .ng-value-container .ng-placeholder { position: absolute; left: 29px; top: 50%; transform: translateY(-50%); padding-left: 0.5rem; margin-top: 0; }
.ng-select.custom-ng-select:not(.ng-select-multiple) .ng-value { min-width: 95%; padding-left: 5px; }

/*.support_tickets_table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell, .support_tickets_table .datatable-row-wrapper .datatable-row-group .datatable-body-cell { min-width: inherit; }*/
.support_tickets_table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-last-child(1) { min-width: 100px; }
.support_tickets_table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-last-child(2), .support_tickets_table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-last-child(3), .support_tickets_table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-last-child(2), .support_tickets_table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-last-child(3) { min-width: 140px; }

.adminUserTable .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell, .adminUserTable .datatable-row-wrapper .datatable-row-group .datatable-body-cell { min-width: 200px; }

.swal2-confirm, .swal2-cancel { width: 100px; border-radius: 0.375rem !important; border-width: 0 !important; padding: 0.6rem 1rem; text-align: center; font-size: 0.88rem !important; }
:is([data-mode=dark] .swal2-popup.swal2-modal.custom-popup-class) { --tw-bg-opacity: 1; background-color: rgb(28 46 69 / var(--tw-bg-opacity)); --tw-text-opacity: 1; color: rgb(255 255 255 / var(--tw-text-opacity)) }
:is([data-mode=dark] .swal2-popup.swal2-modal.custom-popup-class-conformation) { --tw-bg-opacity: 1; background-color: rgb(28 46 69 / var(--tw-bg-opacity)); --tw-text-opacity: 1; color: rgb(255 255 255 / var(--tw-text-opacity)) }
.group[data-sidebar-size=sm] .group-data-\[sidebar-size\=sm\]\:pt-header { position: fixed !important; top: 0; left: 0; }

:is([data-mode=dark] input:-internal-autofill-selected) { box-shadow: 0 0 10px 30px rgb(28 47 69) inset;
  color: rgb(188 201 221) !important; -webkit-text-fill-color: rgb(188 201 221); }
.feature-radio-list app-sk-toggle-switch label span:first-child { display: none; }

.icon-sun { display: none; }
.icon-moon { display: block; }
:is([data-mode='dark'] .icon-moon) { display: none; }
:is([data-mode='dark'] .icon-sun) { display: block; }

.card-user-info { flex-wrap: wrap; }
.card-user-info-box { width: 33.333333%; }
@media only screen and (max-width: 1279px) {
  .card-user-info-box { width: 25%; }
}
@media only screen and (max-width: 639px) {
  .card-user-info-box { width: 33.333333%; }
}
@media only screen and (max-width: 449px) {
  .card-user-info-box { width: 50%; }
  .card .card-user-img.size-24 { width: 4rem; height: 4rem; min-width: 4rem; }
}

.smsSettingTable .ngx-datatable .datatable-body-cell {
  display: flex !important;
  align-items: center !important;
}

@media only screen and (max-width: 767px) {
  .ngx-datatable .datatable-footer .datatable-footer-inner { height: auto !important; }
}


.support_tickets_table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell, .support_tickets_table .datatable-row-wrapper .datatable-row-group .datatable-body-cell { min-width: inherit; }
.ngx-datatable .datatable-body { min-height: 150px; }


.ctmPostLink{
  color: cornflowerblue !important;
  text-decoration: underline !important;
}

.filter_organization { position: relative; z-index: 3; }
.subscription-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(2), .subscription-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), .subscription-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5) { text-align: center; }
.subscription-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label, .subscription-table .datatable-row-center :nth-child(4) > .datatable-body-cell-label, .subscription-table .datatable-row-center :nth-child(5) > .datatable-body-cell-label { width: 100%; text-align: center; }
.subscription-table .datatable-row-center :nth-child(2) > .datatable-body-cell-label div, .subscription-table .datatable-row-center :nth-child(4) > .datatable-body-cell-label div, .subscription-table .datatable-row-center :nth-child(5) > .datatable-body-cell-label div { width: 100%; text-align: center; justify-content: center; }

.subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(3), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(6), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(7), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(8), .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(9) { text-align: center; }
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(3) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(4) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(5) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(6) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(7) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(8) .datatable-body-cell-label,
.subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(9) .datatable-body-cell-label
 { text-align: center; width: 100%; }
// .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(6), .subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(6) { min-width: 250px; }
// .subscription-clients-table .ngx-datatable .datatable-header .datatable-header-cell:nth-child(5), .subscription-clients-table .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(5) { min-width: 250px; }


.clientTable .datatable-header > .datatable-header-inner > .datatable-row-center > *:nth-child(3) .datatable-header-cell-template-wrap { text-align: left; }
.clientTable .ngx-datatable .datatable-header .datatable-header-cell:nth-child(4) .datatable-header-cell-template-wrap, .clientTable .datatable-row-wrapper .datatable-row-group .datatable-body-cell:nth-child(4) .datatable-body-cell-label { text-align: center; }

.form-custom-select.form-custom-select.ng-select.ng-select-single .ng-select-container { height: 45px; }

.announcement_table
  .datatable-header
  > .datatable-header-inner
  > .datatable-row-center
  > *:nth-child(4) {
  text-align: center !important;
}

.announcement_table .datatable-body-cell:nth-child(4) {
  justify-content: center !important;
}

.ql-container {
  height: 15rem !important;
  width: 100% !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-color: #e2e8f0;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-color: #e2e8f0;
}

.quill_custom_container_class {
  width: 100% !important;
}

.quill_container_div {
  @apply text-base leading-normal; /* Reset Tailwind conflicting styles */
}

.ql-editor {
  @apply font-normal; /* Ensure normal font styles */
}

.ql-editor strong {
  font-weight: bold !important; /* Fix bold issue */
}


.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input { height: 20px; padding: 15px 10px !important; box-sizing: border-box; }
.ng-select.ng-select-multiple .ng-select-container .ng-value-container { max-height: 67px; overflow-x: hidden; overflow-y: auto; padding-top: 5px; margin-right: 30px; }
.ng-select.ng-select-multiple .ng-clear-wrapper { display: flex; align-items: center; justify-content: center; margin-left: 4px; }
.filter-select + div { pointer-events: none; }
@media (min-width: 1200px) {
  .subscription-clients-table .filter_organization { min-width: 350px; }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked { font-weight: 600; }

.subscription-clients-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell, 
.subscription-clients-table  .datatable-row-wrapper .datatable-row-group .datatable-body-cell { min-width: 100px; max-width: 100%; }
.subscription-clients-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell { white-space: normal; }
.subscription-clients-table .ngx-datatable .datatable-header { height: auto !important; }


